<template>
  <div class="iframe-preview">
    <div class="iframe-preview__wrapper">
      <action-button @click="onClose" :negative="true" class="control" />
      <div class="iframe-preview__controls">
        <div class="iframe-preview__theme form-control">
          <select @change="onThemeChange" v-model="theme">
            <option value="null" disabled>Theme</option>
            <option value="default">Default</option>
            <option value="lemons">Lemons</option>
            <option value="honey-dew">Honeydew</option>
            <option value="banana">Banana</option>
            <option value="peach">Peach</option>
            <option value="grape">Grape</option>
            <option value="blueberry">Blueberry</option>
            <option value="octopus">Octopus</option>
            <option value="chick">Chick</option>
            <option value="elephant">Elephant</option>
            <option value="tuna">Tuna</option>
            <option value="dolphin">Dolphin</option>
            <option value="whale">Whale</option>
            <option value="flamingo">Flamingo</option>
          </select>
        </div>
        <div class="iframe-preview__email form-control">
          <input type="text" v-model="email" placeholder="Email Address" />
        </div>
        <button @click="sendTest">Send Test</button>
      </div>
      <iframe :src="url" style="width: 800px; margin: 0; height: 800px" />
    </div>
  </div>
</template>

<script>
import ActionButton from "@/components/ActionButton";

export default {
  name: "PreviewIframe",
  components: {
    ActionButton,
  },
  data() {
    return {
      theme: "default",
      email: null,
    };
  },
  props: ["url"],
  methods: {
    onClose: function () {
      this.$emit("preview-close");
    },
    onThemeChange: function () {
      this.$emit("preview-update", this.theme);
    },
    sendTest: function () {
      this.$emit("preview-test", this.theme, this.email);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/colors";

.iframe-preview {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;

  &__wrapper {
    left: 50%;
    position: absolute;
    top: 20px;
    transform: translateX(-50%);
  }

  &__controls {
    background-color: #f7f7f7;
    display: flex;
    padding: 6px;

    button {
      background-color: $dark-blue;
      border: 0;
      color: #fff;
      cursor: pointer;
    }
  }

  .form-control {
    flex-grow: 1;
    margin: 0 4px;

    input {
      padding: 5px 0;
      width: 100%;
    }

    input,
    select {
      margin-bottom: 0;
    }
  }

  .action-button {
    left: calc(100% + 10px);
    position: absolute;
    top: -10px;
  }
}
</style>