function Component() {
  return {
    id: null,
    lander_id: null,
    type: null,
    super_heading: null,
    heading: null,
    sub_heading: null,
    body: null,
    call_to_action: null,
    action: null,
    target_id: null,
    priority: null,
    collection: [],
    media: [],
    cards: [], //Houses card elements
    layout: null, //Select card layout type
    created_at: null,
    updated_at: null,
  };
}

export default Component;
