<template>
  <modal
    :active="active"
    @dismiss="$emit('dismiss')"
    class="create-view-modal form-control"
  >
    <loading :active="loading" />
    <template v-if="view">
      <h2>Create New {{ view.type }}</h2>
      <label>Name</label>
      <input v-model="view.name" type="text" />

      <template v-if="view.type != 'sms'">
        <label>Layout</label>
        <select v-model="view.layout">
          <option value="default">Default</option>
        </select>

        <label>Template</label>
        <select v-model="view.template">
          <option value="default">Default</option>
          <template v-if="view.type == 'lander'">
            <option value="article">Article</option>
          </template>
        </select>

        <label>Assessment</label>
        <select v-model="view.assessment_id">
          <option value="null" disabled>Select An Assessment</option>
          <option
            v-for="(assessment, index) in assessments"
            :key="`assessment-${index}`"
            :value="assessment.id"
          >
            {{ assessment.heading }}
          </option>
        </select>

        <label>Theme</label>
        <select v-model="view.theme">
          <option value="null" disabled>Select A Theme</option>
          <option value="default">Default</option>
        </select>
      </template>

      <template v-if="view.type == 'email' || view.type == 'sms'">
        <label>Event Trigger</label>
        <select v-model="selectedEvents" multiple>
          <option value="null" disabled>Select An Event Trigger</option>
          <option
            :value="event.id"
            v-for="(event, index) in events"
            :key="`event-${index}`"
          >
            {{ event.external_name }}
          </option>
        </select>

        <label>Timing</label>
        <select v-model="view.timing">
          <option value="null" disabled>Select Timing</option>
          <option value="0">Immediately</option>
          <option value="3600">1 Hour</option>
          <option value="86400">24 Hours</option>
          <option value="172800">48 Hours</option>
          <option value="259200">72 Hours</option>
          <option value="604800">1 Week</option>
        </select>
      </template>

      <button class="button" @click="onCreate">Create</button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import Loading from "@/components/Loading";
import { events } from "@/mixins/events";
import { content } from "@/mixins/content";

export default {
  name: "CreateViewModal",
  props: ["active", "view"],
  mixins: [events, content],
  components: {
    Modal,
    Loading,
  },
  computed: {
    loading() {
      return this.$store.getters["loading"];
    },
    selectedEvents: {
      get() {
        const events = this.view.events.map(function (event) {
          return event.id;
        });

        return events;
      },
      set(events) {
        this.view.events = events.map(function (event) {
          return {
            id: event,
          };
        });
      },
    },
  },
  methods: {
    onCreate() {
      this.$store.dispatch("engage/addView", this.view).then((res) => {
        if (res.data) {
          this.$emit("view-created", res.data);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/forms";
@import "@/scss/button";

.create-view-modal {
  h2 {
    text-transform: capitalize;
  }
}
</style>