<template>
	<button class="action-button" v-on:click.stop="onClick" :class="{negative: negative}">
		<svg v-if="!negative" xmlns="http://www.w3.org/2000/svg" width="10.0032" height="10.0032" viewBox="0 0 10.0032 10.0032"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-1" points="10.003 3.89 6.113 3.89 6.113 0 3.89 0 3.89 3.89 0 3.89 0 6.113 3.89 6.113 3.89 10.003 6.113 10.003 6.113 6.113 10.003 6.113 10.003 3.89"/></g></g></svg>

		<svg v-else xmlns="http://www.w3.org/2000/svg" width="10.0032" height="2.2232" viewBox="0 0 10.0032 2.2232"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect class="cls-1" width="10.0032" height="2.2232"/></g></g></svg>
	</button>
</template>

<script>
	export default {
		name: 'ActionButton',
		props: [ 'negative' ],
		methods: {
			onClick() {
				this.$emit('click');
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '@/scss/colors';
	
	.action-button {
		border-radius: 50%;
		border: none;
		font-size: 1rem;
		outline: none;
		background: $dark-blue;
		height: 1.5rem;
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&.negative {
			background: $red;
		}

		svg {
			fill: #FFF;
		}
	}
</style>