<template>
  <div class="input-wrapper">
    <label>{{ label }}</label>

    <div>
      <vue-editor
        :id="editorType"
        v-model="content"
        :editorOptions="editorSettings"
        :editorToolbar="customToolbar"
        @text-change="onUpdate"
      ></vue-editor>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { QuillOptions } from "../../quill/options";
import "quill-paste-smart";

export default {
  props: ["value", "label", "editorType"],
  model: {
    prop: "value",
    event: "update",
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      content: this.value,
      editorSettings: QuillOptions,
    };
  },
  methods: {
    onUpdate: function () {
      this.$emit("update", this.content);
    },
  },
  computed: {
    customToolbar() {
      if (this.editorType === "heading") {
        return ["bold", "italic"];
      }

      return [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["link"],
      ];
    },
  },
  watch: {
    value: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.content = newVal;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/forms";

.ql-container#heading {
  .ql-editor {
    min-height: 50px;

    p {
      margin-bottom: 0 !important;
    }
  }
}

.ql-snow .ql-editor {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.5rem;
  }

  p,
  ul,
  ol {
    margin-bottom: 1rem;
  }
}
</style>
