<template>
  <modal
    :active="active"
    @dismiss="$emit('dismiss')"
    class="media-picker-modal form-control"
  >
    <loading :active="loading" />
    <h2>Select A Media Asset</h2>
    <div class="media-controls">
      <div class="tabs-controller">
        <button
          class="tab"
          :class="{ active: selectedMediaType === 'image' }"
          @click="selectedMediaType = 'image'"
        >
          Images
        </button>
        <button
          class="tab"
          :class="{ active: selectedMediaType === 'icon' }"
          @click="selectedMediaType = 'icon'"
        >
          Icons
        </button>
      </div>
      <div class="media-filter form-control">
        <div class="form-row">
          <input
            type="text"
            v-model="filter"
            v-on:keyup.enter="onFilterMedia"
          />
        </div>
        <button class="button" @click="onFilterMedia">Filter Media</button>
      </div>
    </div>
    <div class="media-wrapper">
      <div
        class="media-item"
        v-for="(item, index) in filteredMedia"
        :key="`media-item-${index}`"
        @click="$emit('on-media-selected', item)"
      >
        <BaseImage v-if="selectedMediaType === 'image'" :src="item.url" />
        <BaseSVG v-else :src="item.url" />
      </div>
    </div>
  </modal>
</template>

<script>
import BaseImage from "@/components/base/BaseImage";
import BaseSVG from "@/components/base/BaseSVG";
import Modal from "@/components/Modal";
import Loading from "@/components/Loading";

export default {
  name: "MediaPicker",
  props: ["active"],
  components: {
    Modal,
    Loading,
    BaseImage,
    BaseSVG,
  },
  data() {
    return {
      selectedMediaType: "image",
      filter: null,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["media/loading"];
    },
    media() {
      return this.$store.getters["media/media"];
    },
    filteredMedia() {
      return this.media.filter((item) => {
        return item.type === this.selectedMediaType;
      });
    },
  },
  methods: {
    onFilterMedia() {
      this.$store.dispatch("media/getMedia", { filter: this.filter });
    },
  },
  mounted() {
    if (!this.media.length) {
      this.$store.dispatch("media/getMedia");
    }
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/media";

.media-picker-modal {
  h2 {
    margin-bottom: 1rem !important;
  }

  .media-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .media-filter {
    display: flex;

    .form-row,
    input {
      margin-bottom: 0 !important;
    }

    .form-row {
      margin-right: 12px;
    }

    .button {
      padding: 0.25rem 0.75rem;
    }
  }
}

.tabs-controller {
  display: flex;
  align-items: flex-start;

  button {
    background: transparent;
    padding: 0 0.75rem;
    border: solid 1px $disabled;
    height: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 0.75rem;
    color: $gray;
    font-weight: 600;
    outline: none;

    svg {
      width: 0.625rem;
      height: 0.625rem;
      fill: $gray;
    }

    & + button {
      border-left: none;
    }

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }

    &.active {
      background: $fade-gray;
    }
  }
}
</style>