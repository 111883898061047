import numeral from 'numeral';
import objectHash from 'object-hash';

export const demographics = {
	data() {
		return {
			races: [
				{
					label: "White",
					keys: ["white"]
				},
				{
					label: "Black",
					keys: ["black"]
				},
				{
					label: "Asian",
					keys: ["asian"]
				},
				{
					label: "Hispanic",
					keys: ["hispanic/latino"]
				},
				{
					label: "Other",
					keys: ["american_indian/alaskan_native", "native_hawaiin/pacific_islander"]
				}
			],
			ages: [
				{
					label: "Under 45",
					keys: ["under_18", "18-24", "25-34", "35-44"]
				},
				{
					label: "45 - 64",
					keys: ["45-54", "55-64"]
				},
				{
					label: "65 - 74",
					keys: ["65-74"]
				},
				{
					label: "Over 74",
					keys: ["75-84", "over_84"]
				}
			],
			payerMixAgeFilter: null,
			payerMixSexFilter: null,
			psychographicAgeFilter: null,
			psychographicSexFilter: null
		}
	},
	computed: {
		loading: function () {
			return this.$store.getters['identification/loading'];
		},
		icd10: function () {
			return this.$store.getters['identification/icd10'];
		},
		engagementRate: function () {
			return this.$store.getters['identification/engagementRate'];
		},
		activationRate: function () {
			return this.$store.getters['identification/activationRate'];
		},
		audienceThreshold: function () {
			return this.$store.getters['identification/audienceThreshold'];
		},
		locations: function () {
			return this.$store.getters['identification/locations'];
		},
		locationsIds: function () {
			return this.locations.map((location) => {
				return location.id;
			});
		},
		places: function () {
			//return this.$store.getters['identification/places'];
			return this.$store.getters['identification/locations'];
		},
		totalPlacesPopulation() {
			return this.places.reduce((total, item) => {
				return total + item.total_population;
			}, 0);
		},
		demographicData: function () {
			return this.$store.getters['identification/demographics'];
		},
		payerMix: function () {
			return this.$store.getters['identification/payerMix'];
		},
		costPerActivation: function () {
			return this.$store.getters['identification/costPerActivation'];
		},
		costPerActivationLabel: function () {
			return numeral(this.costPerActivation).format('$0,0');
		},
		adjustedTotalCost: function () {
			return Math.round(this.totalAdjustedPatientActivations * this.costPerActivation);
		},
		adjustedTotalCostLabel: function () {
			return numeral(this.adjustedTotalCost).format('$0,0');
		},
		adjustedMonthlyCost: function () {
			return Math.round(this.adjustedTotalCost / 12);
		},
		adjustedMonthlyCostLabel: function () {
			return numeral(this.adjustedMonthlyCost).format('$0,0');
		},
		demographicDataSegments: function () {
			let list = [];
			if (this.demographicData.length) {
				return this.getDemographicDataAggregate(this.demographicData.reduce(this.flattenByByICD(this.icd10), []));
			}

			return list;
		},
		adjustedDemographicDataSegments: function () {
			return this.demographicDataSegments.filter((segment) => {
				return segment.case_opportunities > this.audienceThreshold * this.totalCaseOpportunities;
			});
		},
		totalPopulation: function () {
			let total = 0;
			this.demographicData.map((location) => {
				total += parseInt(location.total_population);
			});

			return total;
		},
		totalPopulationLabel: function () {
			return numeral(this.totalPopulation).format('0,0');
		},
		totalCaseOpportunities: function () {
			let total = 0;
			this.demographicData.map((location) => {
				total += parseInt(location.total_case_opportunities);
			});

			return total;
		},
		totalCaseOpportunitiesLabel: function () {
			if (this.totalCaseOpportunities.toString().length > 5) {
				return numeral(this.totalCaseOpportunities).format('0a');
			}

			return numeral(this.totalCaseOpportunities).format('0,0');
		},
		totalAdjustedCaseOpportunities: function () {
			let total = 0;
			this.adjustedDemographicDataSegments.map((segment) => {
				total += segment.case_opportunities;
			});

			return total;
		},
		totalAdjustedCaseOpportunitiesLabel: function () {
			if (this.totalCaseOpportunities.toString().length > 5) {
				return numeral(this.totalAdjustedCaseOpportunities).format('0a');
			}

			return numeral(this.totalAdjustedCaseOpportunities).format('0,0');
		},
		totalPatientEngagements: function () {
			return Math.ceil(this.totalPatientActivations / 0.2);
		},
		totalPatientEngagementsLabel: function () {
			return numeral(this.totalPatientEngagements).format('0,0');
		},
		totalAdjustedPatientEngagements: function () {
			return Math.ceil(this.totalAdjustedPatientActivations / this.engagementRate);
		},
		totalAdjustedPatientEngagementsLabel: function () {
			return numeral(this.totalAdjustedPatientEngagements).format('0,0');
		},
		averageMonthlyAdjustedPatientEngagements: function () {
			return Math.round(this.totalAdjustedPatientEngagements / 12);
		},
		averageMonthlyAdjustedPatientEngagementsLabel: function () {
			return numeral(this.averageMonthlyAdjustedPatientEngagements).format('0,0');
		},
		totalPatientActivations: function () {
			return Math.ceil(this.totalCaseOpportunities * this.marketShare);
		},
		totalPatientActivationsLabel: function () {
			return numeral(this.totalPatientActivations).format('0,0');
		},
		totalAdjustedPatientActivations: function () {
			return Math.ceil(this.totalAdjustedCaseOpportunities * this.marketShare);
		},
		totalAdjustedPatientActivationsLabel: function () {
			return numeral(this.totalAdjustedPatientActivations).format('0,0');
		},
		averageMonthlyAdjustedPatientActivations: function () {
			return Math.round(this.totalAdjustedPatientActivations / 12);
		},
		averageMonthlyAdjustedPatientActivationsLabel: function () {
			return numeral(this.averageMonthlyAdjustedPatientActivations).format('0,0');
		},
		totalEconomicImpact: function () {
			let total = 0;
			this.demographicData.map((location) => {
				total += parseInt(location.total_economic_impact);
			});

			return total;
		},
		economicImpactPerCase: function () {
			if (this.totalCaseOpportunities) {
				return this.totalEconomicImpact / this.totalCaseOpportunities;
			}

			return 0;
		},
		economicImpactPerCaseLabel: function () {
			return numeral(this.economicImpactPerCase).format('$0a');
		},
		totalFee: function () {
			return (this.economicImpactPerCase * 0.05) * this.totalAdjustedPatientActivations;
		},
		totalFeeLabel: function () {
			return numeral(this.totalFee).format('$0,0');
		},
		monthlyFee: function () {
			return (this.economicImpactPerCase * 0.05) * this.averageMonthlyAdjustedPatientActivations;
		},
		monthlyFeeLabel: function () {
			return numeral(this.monthlyFee).format('$0,0');
		},
		estimatedReturn: function () {
			let impact = this.calculateEconomicImpact(this.totalAdjustedPatientActivations, this.economicImpactPerCase);
			let estimate = Math.round(impact / this.totalFee);

			return (estimate) ? estimate : 1;
		},
		estimatedReturnLabel: function () {
			return `${this.estimatedReturn}:1`;
		},
		payerRatio: function () {
			if (this.demographicData) {
				let totalPrivatePay = 0;
				let totalPublicPay = 0;

				this.demographicData.map((location) => {
					totalPrivatePay += location.total_private_pay;
					totalPublicPay += location.total_public_pay;
				});



				return totalPrivatePay / (totalPrivatePay + totalPublicPay);
			}

			return 0;
		},
		payerMixMultiplier: function () {
			return this.payerRatio - (this.payerMix / 100);
		},
		payerMixByRace() {
			return this.races.map((race) => {
				// Get the metrics
				let mix = {
					public: 0,
					private: 0,
					unspecified: 0
				}

				race.keys.map((key) => {
					let keys = {
						race: key
					}

					if (this.payerMixSexFilter) {
						keys['sex'] = this.payerMixSexFilter;
					}

					if (this.payerMixAgeFilter != null) {
						this.ages[this.payerMixAgeFilter].keys.map((k) => {
							keys['age'] = k;
							const total = this.getStatistic(keys, true);
							mix.public += total.public;
							mix.private += total.private;
							mix.unspecified += total.unspecified;
						});
					} else {
						const total = this.getStatistic(keys, true);
						mix.public += total.public;
						mix.private += total.private;
						mix.unspecified += total.unspecified;
					}
				});

				return {
					label: race.label,
					secondaryMetric: mix.private,
					metric: mix.public
				}
			});
		},
		marketShare() {
			if (this.totalCaseOpportunities < 10000) {
				return 0.025;
			} else if (this.totalCaseOpportunities > 10000 && this.totalCaseOpportunities < 25000) {
				return 0.01;
			} else if (this.totalCaseOpportunities > 25000 && this.totalCaseOpportunities < 50000) {
				return 0.005;
			} else {
				return 0.0025;
			}
		}
	},
	methods: {
		percentageOfTotal(numerator, denominator, label) {
			label = label || false;

			if (denominator) {
				if (label) {
					return numeral(numerator / denominator).format('0.00%');
				}

				return numerator / denominator;
			}

			return 0;
		},
		calculateEconomicImpact(cases, impactPerCase, label) {
			label = label || false;
			if (label) {
				return numeral(cases * impactPerCase).format('$0a');
			}

			return cases * impactPerCase;
		},
		getStatistic: function (keys, payer = false) {
			let total = (!payer) ? 0 : { public: 0, private: 0, unsepcified: 0 };
			for (let i = 0; i < this.demographicData.length; i++) {
				if (this.demographicData[i]['demographics'][this.icd10]) {
					loop1:
					for (let n = 0; n < this.demographicData[i].demographics[this.icd10].length; n++) {
						for (var k in keys) {
							if (this.demographicData[i].demographics[this.icd10][n][k] !== keys[k]) {
								continue loop1;
							}
						}

						if (!payer) {
							total += parseInt(this.demographicData[i].demographics[this.icd10][n].case_opportunities);
						} else {
							total.public += parseInt(this.demographicData[i].demographics[this.icd10][n].payer.public);
							total.private += parseInt(this.demographicData[i].demographics[this.icd10][n].payer.private);
							total.unspecified += parseInt(this.demographicData[i].demographics[this.icd10][n].payer.unspecified);
						}
					}
				}
			}
			return total;
		},
		flattenByByICD: function (icd) {
			return (flattened, demo) => {
				const current = demo.demographics[icd] || [];
				return [...flattened, ...current];
			};
		},
		getDemographicDataAggregate: function (demographics) {
			let map = new Map;
			let aggregate = [];

			demographics.forEach((a) => {
				let key = objectHash({
					race: a.race,
					sex: a.sex,
					age: a.age
				});

				var o = map.get(key);
				if (!o) {
					o = {
						race: a.race,
						sex: a.sex,
						age: a.age,
						case_opportunities: a.case_opportunities
					}
					map.set(key, o);
					aggregate.push(o);
				} else {
					o.case_opportunities += a.case_opportunities;
				}
			});

			return aggregate;
		},
		getPsychographicEstimate(percentage) {
			let keys = {};
			let total = 0;

			if (this.psychographicSexFilter) {
				keys['sex'] = this.psychographicSexFilter;
			}

			if (this.psychographicAgeFilter != null) {
				this.ages[this.psychographicAgeFilter].keys.map((k) => {
					keys['age'] = k;
					total += this.getStatistic(keys);
				});
			} else {
				total = this.getStatistic(keys);
			}

			return Math.round(total * percentage);
		}
	}
}