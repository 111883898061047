<template>
	<modal :active="active" @dismiss="$emit('dismiss')" class="create-ad-modal form-control">
		<loading :active="loading" />
		<template v-if="ad">
			<h2>Create New Ad</h2>
			<label>Name</label>
			<input v-model="ad.name" type="text" />

			<button class="button" @click="onCreate">
				Create
			</button>
		</template>
	</modal>
</template>

<script>
	import Modal from '@/components/Modal';
	import Loading from '@/components/Loading';

	export default {
		name: 'CreateAdModal',
		props: [ 'active', 'ad' ], 
		components: {
			Modal,
			Loading
		},
		computed: {
			loading() {
				return this.$store.getters['loading'];
			}
		},
		methods: {
			onCreate() {
				this.$store.dispatch('engage/addAd', this.ad)
				.then((res) => {
					if (res.data) {
						this.$emit('ad-created', res.data);
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	@import '@/scss/colors';
	@import '@/scss/forms';
	@import '@/scss/button';

	.create-ad-modal {
		h2 {
			text-transform: capitalize;
		}
	}
</style>