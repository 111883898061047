<template>
  <div class="content-component">
    <content-component-picker
      :active="contentTypePickerActive"
      :viewType="viewType"
      @content-type-selected="onContentTypeSelected"
      @dismiss-content-type-picker="onDismissContentTypePicker"
    />

    <template v-if="content">
      <slide-toggle class="card">
        <template v-slot:header>
          <div class="content-component-header">
            <img :src="icon" />
            <h3>{{ label }}</h3>
            <button v-on:click.stop="onRemoveComponent">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10.0032"
                height="2.2232"
                viewBox="0 0 10.0032 2.2232"
              >
                <defs></defs>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <rect class="cls-1" width="10.0032" height="2.2232" />
                  </g>
                </g>
              </svg>
            </button>
          </div>
        </template>
        <template v-slot:body>
          <div class="content-component-body form-control">
            <div
              v-for="(field, field_index) in componentFields"
              :key="`field-${field_index}`"
            >
              <template v-if="field.type == 'text'">
                <InputField
                  :label="field.label"
                  v-model="content[field.model]"
                  @update="onComponentUpdate"
                />
              </template>
              <template v-else-if="field.type == 'textarea'">
                <TextareaField
                  :label="field.label"
                  v-model="content[field.model]"
                  @update="onComponentUpdate"
                />
              </template>
              <template v-else-if="field.type == 'editor'">
                <EditorField
                  :label="field.label"
                  :editorType="field.editorType"
                  v-model="content[field.model]"
                  @update="onComponentUpdate"
                />
              </template>
              <template
                v-else-if="field.type == 'select' && checkVisible(field)"
              >
                <SelectField
                  :label="field.label"
                  v-model="content[field.model]"
                  @update="onComponentUpdate"
                  :options="getSelectOptions(field)"
                  :disabled="checkDisabled(field)"
                />
              </template>
              <template v-else-if="field.type == 'media'">
                <MediaField
                  :label="field.label"
                  v-model="content[field.model]"
                  @update="onComponentUpdate"
                />
              </template>
              <template v-else-if="field.type == 'cards'">
                <div class="card-wrapper">
                  <h2>
                    <label>Cards</label>
                    <button class="button outline" @click="onAddCard">
                      Add Card
                    </button>
                  </h2>

                  <div
                    v-for="(card, index) in content.body"
                    :key="`card-${index}`"
                    class="card-item"
                  >
                    <div class="card-header">
                      <h3>Card #{{ index + 1 }}</h3>
                      <button class="button" @click="onRemoveCard(index)">
                        Remove
                      </button>
                    </div>

                    <InputField
                      label="Heading"
                      v-model="card.heading"
                      @update="onComponentUpdate"
                    />

                    <template v-if="content.layout === 'icons'">
                      <EditorField
                        label="Description"
                        editorType="body"
                        v-model="card.description"
                        @update="onComponentUpdate"
                      />
                    </template>
                    <template v-else>
                      <InputField
                        label="Description"
                        v-model="card.description"
                        @update="onComponentUpdate"
                      />
                    </template>

                    <MediaField
                      label="Thumbnail"
                      v-model="card.thumbnail"
                      @update="onComponentUpdate"
                    />

                    <MediaField
                      label="Icon"
                      v-model="card.icon"
                      @update="onComponentUpdate"
                    />

                    <InputField
                      label="Call to Action"
                      v-model="card.cta_text"
                      @update="onComponentUpdate"
                    />

                    <InputField
                      label="Action Href"
                      v-model="card.cta_href"
                      @update="onComponentUpdate"
                    />
                  </div>
                </div>
              </template>
              <!-- START LIST -->
              <template v-else-if="field.type == 'list'">
                <div class="card-wrapper">
                  <h2>
                    <label>List Items</label>
                    <button class="button outline" @click="onAddListItem">
                      Add List Item
                    </button>
                  </h2>

                  <div
                    v-for="(item, index) in content.body"
                    :key="`item-${index}`"
                    class="item-item"
                  >
                    <div class="card-header">
                      <h3>Item #{{ index + 1 }}</h3>
                      <button class="button" @click="onRemoveListItem(index)">
                        Remove
                      </button>
                    </div>

                    <InputField
                      label="Heading"
                      v-model="item.heading"
                      @update="onComponentUpdate"
                    />

                    <EditorField
                      label="Description"
                      editorType="body"
                      v-model="item.description"
                      @update="onComponentUpdate"
                    />

                    <template v-if="content.layout === 'block'">
                      <MediaField
                        label="Thumbnail"
                        v-model="item.thumbnail"
                        @update="onComponentUpdate"
                      />
                    </template>
                  </div>
                </div>
              </template>
              <!-- END LIST -->

              <template v-else-if="field.type == 'checkbox'">
                <CheckboxField
                  :label="field.label"
                  :options="field.options"
                  v-model="collection"
                  @update="onCollectionChanged"
                />
              </template>
            </div>
          </div>
        </template>
      </slide-toggle>
      <div class="trigger-wrapper">
        <button @click="onAddContentType">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.0032"
            height="10.0032"
            viewBox="0 0 10.0032 10.0032"
          >
            <defs></defs>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <polygon
                  class="cls-1"
                  points="10.003 3.89 6.113 3.89 6.113 0 3.89 0 3.89 3.89 0 3.89 0 6.113 3.89 6.113 3.89 10.003 6.113 10.003 6.113 6.113 10.003 6.113 10.003 3.89"
                />
              </g>
            </g>
          </svg>
        </button>
      </div>
    </template>
    <div v-else class="empty-view-wrapper">
      <p><strong>You haven't added any components</strong></p>
      <button @click="onAddContentType" class="button">Add A Component</button>
    </div>
  </div>
</template>

<script>
import SlideToggle from "@/components/SlideToggle.vue";
import ContentComponentPicker from "@/components/ContentComponentPicker.vue";
import Component from "@/models/Component";
import { content } from "@/mixins/content";
import InputField from "@/components/fields/InputField";
import TextareaField from "@/components/fields/TextareaField";
import SelectField from "@/components/fields/SelectField";
import EditorField from "@/components/fields/EditorField";
import MediaField from "@/components/fields/MediaField";
import CheckboxField from "@/components/fields/CheckboxField";
import Card from "@/models/Card";
import ListItem from "@/models/ListItem";

export default {
  name: "ContentComponent",
  props: ["content", "viewId", "viewType", "actions"],
  components: {
    SlideToggle,
    ContentComponentPicker,
    InputField,
    TextareaField,
    SelectField,
    EditorField,
    MediaField,
    CheckboxField,
  },
  mixins: [content],
  data() {
    return {
      contentTypePickerActive: false,
      contentTypeAction: null,
      collection: [],
      mediaPickerActive: false,
      cardModel: null,
      listModel: null,
    };
  },
  computed: {
    componentFields() {
      return this.contentTypes[this.content.type].fields || [];
    },
    label() {
      return this.contentTypes[this.content.type].label;
    },
    icon() {
      return this.contentTypes[this.content.type].icon;
    },
  },
  methods: {
    onAddCard() {
      // If content body has not been set
      // Create new array
      if (!this.content.body) {
        this.content.body = [];
      }
      this.content.body.push(new Card());
    },
    onAddListItem() {
      try {
        if (!this.content.body) {
          this.content.body = [];
        }
        this.content.body.push(new ListItem());
      } catch (e) {
        console.log(e);
      }
    },
    onAddNewComponent(type) {
      let _component = new Component();
      _component.type = type;
      _component.view_id = this.viewId;
      _component.priority = this.content ? this.content.priority + 1 : 0;

      this.$store.dispatch("engage/addComponentToView", _component);
    },
    onRemoveCard(index) {
      this.content.body.splice(index, 1);

      this.$store.dispatch("engage/updateViewComponent", this.content);
    },
    onRemoveListItem(index) {
      this.content.body.splice(index, 1);

      this.$store.dispatch("engage/updateViewComponent", this.content);
    },
    onRemoveComponent() {
      this.$store.dispatch("engage/removeComponentFromView", this.content);
    },
    onContentTypeSelected(val) {
      this.contentTypePickerActive = false;
      if (this.contentTypeAction === "add") {
        this.onAddNewComponent(val);
      } else if (this.contentTypeAction === "edit") {
        this.content.type = val;
        this.onComponentUpdate();
      }
      this.contentTypeAction = null;
    },
    onDismissContentTypePicker() {
      this.contentTypePickerActive = false;
    },
    onComponentUpdate() {
      this.$store.dispatch("engage/updateViewComponent", this.content);
    },
    onAddContentType() {
      this.contentTypePickerActive = true;
      this.contentTypeAction = "add";
    },
    onChangeContentType() {
      this.contentTypePickerActive = true;
      this.contentTypeAction = "edit";
    },
    onCollectionChanged() {
      this.$store.dispatch("engage/updateCollection", {
        id: this.content.id,
        collection: this.collection,
      });
    },
    checkDisabled(field) {
      if (typeof field.updateOn != "undefined") {
        if (typeof field.disabled == "function") {
          return field.disabled(this.content[field.updateOn]);
        }
      }

      return false;
    },
    checkVisible(field) {
      if (typeof field.updateOn != "undefined") {
        if (typeof field.visible == "function") {
          return field.visible(this.content[field.updateOn]);
        }
      }

      return true;
    },
    getSelectOptions(field) {
      if (field.model === "target_id") {
        return field.options.filter((option) => {
          return option.value != this.content.id;
        });
      }

      return field.options;
    },
  },
  mounted() {
    if (this.content) {
      if (this.content.collection) {
        this.collection = this.content.collection.map((item) => {
          return item.content_offer_id;
        });
      }
    }
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/forms";

.card-wrapper {
  margin-top: 1em;
  width: 100%;

  h2 {
    label {
      font-size: 0.875rem;
      font-weight: 600;
    }
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  h3 {
    font-weight: bold;
  }
}

.card-item {
  padding: 1.5rem;

  &:nth-child(even) {
    background: $fade-gray;
  }
}

.content-component {
  .card {
    padding: 1.5rem !important;
  }

  &-header {
    display: flex;
    align-items: center;
    height: 48px;
    margin-left: 20px;

    img {
      width: 64px;
      margin-right: 1rem;
    }

    h3 {
      text-transform: capitalize;
      font-weight: 800;
      min-width: 4rem;
    }

    button {
      margin-left: auto;
      border-radius: 50%;
      border: none;
      font-size: 1rem;
      outline: none;
      background: $red;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        fill: #fff;
      }
    }
  }
  &-body {
    padding-top: 1.5rem;
    margin-top: 1.5rem;
    border-top: solid 2px $fade-gray;

    .edit-type-button {
      background: transparent;
      color: $gray;
      border: solid 1px $disabled;
      margin-left: auto;

      &:hover {
        background: transparent;
      }
    }

    .input-wrapper {
      display: flex;
      padding-bottom: 1.5rem;

      label {
        font-size: 0.875rem;
        font-weight: 600;
        width: 192px;
      }
      input[type="text"],
      select,
      textarea {
        margin-left: auto;
        border: solid 1px $disabled;
        border-radius: 4px;
        padding: 0.5rem;
        margin-bottom: 0;
        width: calc(100% - 192px);
      }
      label + div {
        margin-left: auto;
        width: calc(100% - 192px);
      }
    }
  }

  .trigger-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    z-index: 0;

    button {
      position: relative;
      z-index: 1;
      border-radius: 50%;
      border: none;
      font-size: 1rem;
      outline: none;
      background: $dark-blue;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: #fff;
      }
    }

    &:before {
      content: "";
      display: block;
      background-color: $disabled;
      width: 2px;
      height: 100%;
      position: absolute;
      left: 50%;
      margin-left: -1px;
      top: 0;
      z-index: 0;
    }
  }

  &:last-of-type {
    .trigger-wrapper {
      &:before {
        height: 50%;
      }
    }
  }
}

.ql-toolbar {
  &.ql-snow {
    border-radius: 4px 4px 0 0;
    border-color: $disabled !important;
  }
}

.ql-container {
  &.ql-snow {
    border-radius: 0 0 4px 4px;
    border-color: $disabled !important;
  }
}

.ql-editor {
  font-family: "Nunito Sans", sans-serif;
}

.draggable-mirror {
  .content-component {
    .trigger-wrapper {
      visibility: hidden !important;
    }
  }
}
</style>