function Card() {
    return {
        thumbnail: [],
        icon: [],
        heading: null,
        description: null,
        cta_text: null,
        cta_href: null,
    }
}

export default Card;