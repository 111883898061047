<template>
  <div>
    <media-picker
      :active="mediaPickerActive"
      @dismiss="mediaPickerActive = false"
      @on-media-selected="onMediaSelected"
    />

    <div class="input-wrapper">
      <label>{{ label }}</label>
      <div
        class="media-wrapper"
        @click="onActivateMedia"
        @dismiss="mediaPickerActive = false"
      >
        <template v-if="images && images.length">
          <div
            class="media-item"
            :key="`item-${index}`"
            v-for="(item, index) in images"
          >
            <ActionButton :negative="true" @click="onRemoveMediaItem(item)" />
            <BaseImage :src="item.url" />
          </div>
        </template>
        <template v-else>
          <div class="media-item__empty">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M19.5 12c-2.483 0-4.5 2.015-4.5 4.5s2.017 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.017-4.5-4.5-4.5zm2.5 5h-2v2h-1v-2h-2v-1h2v-2h1v2h2v1zm-18 0l4-5.96 2.48 1.96 2.52-4 1.853 2.964c-1.271 1.303-1.977 3.089-1.827 5.036h-9.026zm10.82 4h-14.82v-18h22v7.501c-.623-.261-1.297-.422-2-.476v-5.025h-18v14h11.502c.312.749.765 1.424 1.318 2zm-9.32-11c-.828 0-1.5-.671-1.5-1.5 0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5z"
              />
            </svg>
            <p>Choose an Image</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from "@/components/ActionButton";
import BaseImage from "@/components/base/BaseImage";
import MediaPicker from "@/components/MediaPicker.vue";

export default {
  props: ["value", "label"],
  model: {
    prop: "value",
    event: "update",
  },
  components: {
    ActionButton,
    BaseImage,
    MediaPicker,
  },
  data() {
    return {
      images: this.value || [],
      mediaPickerActive: false,
    };
  },
  methods: {
    onActivateMedia() {
      this.mediaPickerActive = true;
    },
    onMediaSelected(val) {
      this.mediaPickerActive = false;
      this.images.push(val);

      this.onUpdate();
    },
    onRemoveMediaItem(item) {
      const index = this.images.findIndex((mediaItem) => {
        return mediaItem.id == item.id;
      });

      if (typeof index != "undefined") {
        this.images.splice(index, 1);
        this.onUpdate();
      }
    },
    onUpdate() {
      this.$emit("update", this.images);
    },
  },
  watch: {
    value: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.images = this.value || [];
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
.input-wrapper {
  .media-wrapper {
    padding: 1rem;
    width: 100%;
    border: dashed 3px $fade-gray;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    align-items: center;
    justify-content: center;

    .media-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 8px;
      background-color: $fade-gray;
      border-radius: 4px;
      height: 128px;
      width: 128px;
    }

    img {
      max-width: 128px;
      max-height: 128px;
    }

    p {
      font-size: 0.75rem;
      font-weight: 600;
      margin-top: 0.5rem;
      color: $medium-gray;
    }

    svg {
      fill: $disabled;
      width: 48px;
      height: 48px;
    }

    .action-button svg {
      height: auto;
      position: static;
      width: auto;
    }
  }
  .media-item {
    position: relative;
    overflow: hidden;

    .action-button {
      position: absolute;
      top: 4px;
      right: 4px;
      transform: translate3d(0, 0, 0);
      z-index: 1;

      svg {
        fill: #fff !important;
      }
    }

    &__empty {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
