function View() {
  return {
    id: null,
    campaign_id: null,
    assessment_id: null,
    name: null,
    type: null,
    template: "default",
    layout: "default",
    theme: "default",
    events: [],
    timing: null,
    is_default: null,
    components: [],
    metas: [],
    meta_data: () => ({}),
    created_at: null,
    updated_at: null,
  };
}

export default View;
