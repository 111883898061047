<template>
  <div class="checkbox-wrapper">
    <div @click="clearSelections" class="checkbox-wrapper__clear">
      Clear Selections
    </div>
    <span
      class="checkbox-item"
      v-for="(option, index) in options"
      :key="`option-${index}`"
    >
      <label>
        <input
          type="checkbox"
          v-model="selections"
          :value="option.id"
          @change="updateSelections"
        />
        <span class="checkbox-item__title">
          {{ option.title }}
        </span>
      </label>
    </span>
  </div>
</template>

<script>
export default {
  props: ["value", "label", "options"],
  model: {
    prop: "value",
    event: "update",
  },
  data() {
    return {
      selections: this.value || [],
    };
  },
  methods: {
    clearSelections() {
      this.selections = [];
      this.updateSelections();
    },
    updateSelections() {
      this.$emit("update", this.selections);
    },
  },
  watch: {
    value: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selections = this.value || [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/colors";

.checkbox-wrapper {
  display: flex;
  flex-wrap: wrap;

  &__clear {
    color: $blue;
    cursor: pointer;
    font-size: 12px;
    width: 100%;
  }

  .checkbox-item {
    width: 33.3333%;
    padding: 1rem;
    border-bottom: solid 1px $fade-gray;

    label {
      align-items: center;
      display: flex;
      font-size: 0.875rem;
      font-weight: 400;
    }

    input[type="checkbox"] {
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 0.5rem;
    }
  }
}
</style>
