<template>
  <div class="campaign">
    <loading :active="loading" :fullscreen="true" />

    <preview-iframe
      v-if="previewUrl"
      v-on:preview-close="onPreviewClose"
      v-on:preview-update="previewEmail"
      v-on:preview-test="testEmail"
      :url="previewUrl"
    />

    <create-view-modal
      :active="createViewModalActive"
      :view="newView"
      @dismiss="createViewModalActive = false"
      @view-created="onViewCreated"
    />

    <create-ad-modal
      :active="createAdModalActive"
      :ad="newAd"
      @dismiss="createAdModalActive = false"
      @ad-created="onAdCreated"
    />

    <div class="hero hero--engage">
      <div class="container">
        <template v-if="campaign">
          <h2>
            {{ campaign.name }}
          </h2>
          <div class="hero__controls">
            <toggle-switch
              :checked="campaign.status == 'active'"
              @on-change="onStatusChange"
            />

            <MenuButton
              :label="'Campaign Settings'"
              :position="'right'"
              :buttonClasses="'button'"
            >
              <div class="menu-settings form-control">
                <h3>Contact Center</h3>

                <label>Contact Center Call Type</label>
                <input
                  type="text"
                  v-model="campaign.external_call_type"
                  @change="onSaveCampaign"
                />

                <label>Contact Center API Key</label>
                <input
                  type="text"
                  v-model="campaign.api_key"
                  @change="onSaveCampaign"
                />

                <h3>Exports</h3>
                <div class="label-button">
                  <label class="label-button__label"
                    >Ad URLs &amp; Params</label
                  >
                  <button
                    class="button outline label-button__button"
                    @click="onDownloadAdURLs(adExportUrl)"
                  >
                    Download
                  </button>
                </div>
              </div>
            </MenuButton>
          </div>
        </template>
      </div>
    </div>
    <div class="container">
      <template v-if="campaign">
        <div class="campaign-components-wrapper">
          <div class="campaign-wrapper">
            <div class="card">
              <!-- Ads -->
              <slide-toggle>
                <template v-slot:header>
                  <p>Ads</p>
                  <pill :count="ads.length" :label="true" />
                  <action-button @click="onCreateNewAd()" />
                </template>
                <template v-slot:body>
                  <ul v-if="ads.length">
                    <li
                      @click="$store.dispatch('engage/setSelectedContent', ad)"
                      v-for="(ad, index) in ads"
                      :key="`ad-${index}`"
                      :class="{ selected: selectedContent == ad }"
                    >
                      <span>{{ ad.name | truncate }}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path
                              d="M12,0A12,12,0,1,0,24,12,12.0005,12.0005,0,0,0,12,0ZM10.432,18.005,9.018,16.59,13.592,12,9.018,7.421l1.414-1.416L16.42,12Z"
                            />
                          </g>
                        </g>
                      </svg>
                    </li>
                  </ul>
                  <p v-else>No Ads Created</p>
                </template>
              </slide-toggle>

              <!-- Views -->
              <slide-toggle>
                <template v-slot:header>
                  <p>Landers</p>
                  <pill :count="landers.length" :label="true" />
                  <action-button @click="onCreateNewView('lander')" />
                </template>
                <template v-slot:body>
                  <ul v-if="landers.length">
                    <li
                      @click="onSelectView(lander)"
                      v-for="(lander, index) in landers"
                      :key="`lander-${index}`"
                      :class="{ selected: selectedContent == lander }"
                    >
                      <span>{{ lander.name | truncate }}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path
                              d="M12,0A12,12,0,1,0,24,12,12.0005,12.0005,0,0,0,12,0ZM10.432,18.005,9.018,16.59,13.592,12,9.018,7.421l1.414-1.416L16.42,12Z"
                            />
                          </g>
                        </g>
                      </svg>
                    </li>
                  </ul>
                  <p v-else>No Landers Created</p>
                </template>
              </slide-toggle>

              <!-- Email -->
              <slide-toggle>
                <template v-slot:header>
                  <p>Email</p>
                  <pill :count="emails.length" :label="true" />
                  <action-button @click="onCreateNewView('email')" />
                </template>
                <template v-slot:body>
                  <ul v-if="emails.length">
                    <li
                      @click="onSelectView(email)"
                      v-for="(email, index) in emails"
                      :key="`email-${index}`"
                      :class="{ selected: selectedContent == email }"
                    >
                      <span>{{ email.name | truncate }}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path
                              d="M12,0A12,12,0,1,0,24,12,12.0005,12.0005,0,0,0,12,0ZM10.432,18.005,9.018,16.59,13.592,12,9.018,7.421l1.414-1.416L16.42,12Z"
                            />
                          </g>
                        </g>
                      </svg>
                    </li>
                  </ul>
                  <p v-else>No Emails Created</p>
                </template>
              </slide-toggle>

              <!-- SMS -->
              <slide-toggle>
                <template v-slot:header>
                  <p>Text Message</p>
                  <pill :count="sms.length" :label="true" />
                  <action-button @click="onCreateNewView('sms')" />
                </template>
                <template v-slot:body>
                  <ul v-if="sms.length">
                    <li
                      @click="onSelectView(msg)"
                      v-for="(msg, index) in sms"
                      :key="`msg-${index}`"
                      :class="{ selected: selectedContent == msg }"
                    >
                      <span>{{ msg.name | truncate }}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path
                              d="M12,0A12,12,0,1,0,24,12,12.0005,12.0005,0,0,0,12,0ZM10.432,18.005,9.018,16.59,13.592,12,9.018,7.421l1.414-1.416L16.42,12Z"
                            />
                          </g>
                        </g>
                      </svg>
                    </li>
                  </ul>
                  <p v-else>No Messages Created</p>
                </template>
              </slide-toggle>
            </div>
          </div>

          <div class="components-wrapper">
            <template v-if="selectedContent">
              <div class="card active">
                <header class="components-wrapper-header">
                  <input
                    type="text"
                    class="components-wrapper-header__input"
                    v-model="selectedContent.name"
                    @change="onContentChanged"
                  />
                  <div class="controls">
                    <button
                      v-if="selectedContent.type === 'lander'"
                      class="button control"
                      target="_blank"
                      @click="
                        onPreviewLander(
                          `${baseURL}/${campaign.slug}/${selectedContent.slug}?token=${selectedContent.preview_token}`
                        )
                      "
                    >
                      Preview
                    </button>
                    <button
                      v-if="selectedContent.type === 'email'"
                      @click="onPreviewEmail"
                      class="button control"
                    >
                      Preview
                    </button>

                    <MenuButton
                      :label="'Settings'"
                      :position="'right'"
                      :buttonClasses="'button outline control'"
                      v-if="selectedContent.type != 'sms'"
                    >
                      <div class="menu-settings form-control">
                        <template v-if="selectedContent.type === 'lander'">
                          <div class="default-toggle-wrapper">
                            <toggle-switch
                              :checked="selectedContent.status == 'active'"
                              @on-change="onViewStatusChange"
                            />
                            View Active
                          </div>

                          <div class="default-toggle-wrapper">
                            <input
                              type="checkbox"
                              v-model="selectedContent.is_default"
                              @change="onDefaultSelected"
                            />
                            Set As Default
                          </div>

                          <div class="default-toggle-wrapper">
                            <toggle-switch
                              :checked="
                                selectedContent.meta_data.chat == 'active'
                              "
                              @on-change="onViewChatChange"
                            />
                            Chat Active
                          </div>

                          <label>Assessment</label>
                          <select
                            v-model="selectedContent.assessment_id"
                            @change="onContentChanged"
                          >
                            <option value="null" disabled>
                              Select An Assessment
                            </option>
                            <option
                              v-for="(assessment, index) in assessments"
                              :key="`assessment-${index}`"
                              :value="assessment.id"
                            >
                              {{ assessment.heading }}
                            </option>
                          </select>

                          <h3>Meta Data</h3>

                          <label>Meta Title</label>
                          <input
                            type="text"
                            v-model="selectedContent.meta_data.title"
                            @change="onContentChanged"
                          />

                          <label>Meta Description</label>
                          <textarea
                            v-model="selectedContent.meta_data.description"
                            @change="onContentChanged"
                          />

                          <label>Open Graph Image</label>
                          <input
                            type="url"
                            v-model="selectedContent.meta_data.image"
                            @change="onContentChanged"
                          />
                        </template>

                        <template v-else-if="selectedContent.type === 'email'">
                          <h3>Meta Data</h3>

                          <label>Subject Line</label>
                          <input
                            type="text"
                            v-model="selectedContent.meta_data.subject"
                            @change="onContentChanged"
                          />

                          <label>Preview Text</label>
                          <input
                            type="text"
                            v-model="selectedContent.meta_data.preview"
                            @change="onContentChanged"
                          />
                        </template>

                        <template
                          v-else-if="
                            viewTypes.indexOf(selectedContent.type) === -1
                          "
                        >
                          <label>Preview URL</label>
                          <input
                            type="text"
                            v-model="selectedContent.preview_url"
                            @change="onContentChanged"
                          />
                        </template>
                      </div>
                    </MenuButton>

                    <button
                      v-if="
                        selectedContent.type === 'lander' ||
                        selectedContent.type === 'email'
                      "
                      class="button control"
                      target="_blank"
                      @click="onCloneContent"
                    >
                      Clone
                    </button>

                    <action-button
                      @click="onDeleteContent"
                      :negative="true"
                      class="control"
                    />
                  </div>
                </header>

                <div class="tags-wrapper">
                  <pill
                    v-for="(tag, index) in tags"
                    :count="tag.name"
                    :key="`tag-${index}`"
                    :label="true"
                    :clickable="true"
                    :enabled="tagIndex(tag) > -1"
                    @on-click="onTagClicked(tag)"
                  />
                </div>

                <div class="content-settings form-control">
                  <template v-if="selectedContent.type === 'lander'">
                    <!-- View Settings -->
                    <div class="cell">
                      <label>Layout</label>
                      <div class="setting-item-wrapper">
                        <select
                          v-model="selectedContent.layout"
                          @change="onContentChanged"
                        >
                          <option value="null" disabled>Select A Layout</option>
                          <option value="default">Default</option>
                        </select>
                      </div>
                    </div>
                    <div class="cell">
                      <label>Template</label>
                      <div class="setting-item-wrapper">
                        <select
                          v-model="selectedContent.template"
                          @change="onContentChanged"
                        >
                          <option value="null" disabled>
                            Select A Template
                          </option>
                          <option value="default">Default</option>
                          <option value="fullscreen">Fullscreen</option>
                          <option value="alternate">Alternate</option>
                          <option value="content">Content</option>
                          <option value="article">Article</option>
                        </select>
                      </div>
                    </div>
                    <div class="cell">
                      <label>Theme</label>
                      <div class="setting-item-wrapper">
                        <select
                          v-model="selectedContent.theme"
                          @change="onContentChanged"
                        >
                          <option value="null" disabled>Select A Theme</option>
                          <option value="default">Default</option>
                          <option value="lemons">Lemons</option>
                          <option value="honey-dew">Honeydew</option>
                          <option value="banana">Banana</option>
                          <option value="peach">Peach</option>
                          <option value="grape">Grape</option>
                          <option value="blueberry">Blueberry</option>
                          <option value="octopus">Octopus</option>
                          <option value="chick">Chick</option>
                          <option value="elephant">Elephant</option>
                          <option value="tuna">Tuna</option>
                          <option value="dolphin">Dolphin</option>
                          <option value="whale">Whale</option>
                          <option value="flamingo">Flamingo</option>
                        </select>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="selectedContent.type === 'email'">
                    <!-- View Settings -->
                    <div class="cell">
                      <label>Event Trigger</label>
                      <div class="setting-item-wrapper">
                        <select
                          v-model="selectedEvents"
                          @change="onContentChanged"
                          multiple
                        >
                          <option value="null" disabled>Select An Event</option>
                          <option
                            :value="event.id"
                            v-for="(event, index) in events"
                            :key="`event-${index}`"
                          >
                            {{ event.external_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="cell">
                      <label>Theme</label>
                      <div class="setting-item-wrapper">
                        <select
                          v-model="selectedContent.theme"
                          @change="onContentChanged"
                        >
                          <option value="null" disabled>Select A Theme</option>
                          <option value="inherit">Inherit</option>
                          <option value="default">Default</option>
                          <option value="lemons">Lemons</option>
                          <option value="honey-dew">Honeydew</option>
                          <option value="banana">Banana</option>
                          <option value="peach">Peach</option>
                          <option value="grape">Grape</option>
                          <option value="blueberry">Blueberry</option>
                          <option value="octopus">Octopus</option>
                          <option value="chick">Chick</option>
                          <option value="elephant">Elephant</option>
                          <option value="tuna">Tuna</option>
                          <option value="dolphin">Dolphin</option>
                          <option value="whale">Whale</option>
                          <option value="flamingo">Flamingo</option>
                        </select>
                      </div>
                    </div>
                    <div class="cell">
                      <label>Timing</label>
                      <div class="setting-item-wrapper">
                        <select
                          v-model="selectedContent.timing"
                          @change="onContentChanged"
                        >
                          <option value="null" disabled>Select Timing</option>
                          <option value="0">Immediately</option>
                          <option value="3600">1 Hour</option>
                          <option value="86400">24 Hours</option>
                          <option value="172800">48 Hours</option>
                          <option value="259200">72 Hours</option>
                          <option value="604800">1 Week</option>
                        </select>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="selectedContent.type === 'sms'">
                    <div class="cell">
                      <label>Event Trigger</label>
                      <div class="setting-item-wrapper">
                        <select
                          v-model="selectedEvents"
                          @change="onContentChanged"
                          multiple
                        >
                          <option value="null" disabled>Select An Event</option>
                          <option
                            :value="event.id"
                            v-for="(event, index) in events"
                            :key="`event-${index}`"
                          >
                            {{ event.external_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="cell">
                      <label>Timing</label>
                      <div class="setting-item-wrapper">
                        <select
                          v-model="selectedContent.timing"
                          @change="onContentChanged"
                        >
                          <option value="null" disabled>Select Timing</option>
                          <option value="0">Immediately</option>
                          <option value="3600">1 Hour</option>
                          <option value="86400">24 Hours</option>
                          <option value="172800">48 Hours</option>
                          <option value="259200">72 Hours</option>
                          <option value="604800">1 Week</option>
                        </select>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="cell">
                      <!-- AD Settings -->
                      <div class="setting-item-wrapper">
                        <label>Ad Type</label>
                        <select
                          v-model="selectedContent.type"
                          @change="onContentChanged"
                        >
                          <option value="null" disabled>
                            Select An Ad Type
                          </option>
                          <option value="search">Search</option>
                          <option value="social">Social</option>
                          <option value="video">Video</option>
                          <option value="display">Display</option>
                        </select>
                      </div>
                    </div>
                    <div class="cell">
                      <div class="setting-item-wrapper">
                        <label>Target</label>
                        <select
                          v-model="selectedContent.view_id"
                          @change="onContentChanged"
                        >
                          <option value="null" disabled>
                            Select A Landing Page
                          </option>
                          <option
                            :value="lander.id"
                            v-for="(lander, index) in landers"
                            :key="`lander-target-${index}`"
                          >
                            {{ lander.name | truncate }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="cell">
                      <div class="setting-item-wrapper">
                        <label>Ad Platform</label>
                        <select
                          v-model="selectedContent.source"
                          @change="onContentChanged"
                        >
                          <option value="null" disabled>
                            Select A Platform
                          </option>
                          <option value="google">Google</option>
                          <option value="facebook">Facebook</option>
                        </select>
                      </div>
                    </div>
                    <div class="cell">
                      <div class="setting-item-wrapper">
                        <label>Ad Id</label>
                        <input
                          type="text"
                          v-model="selectedContent.source_id"
                          @change="onContentChanged"
                        />
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <template
                v-if="
                  selectedContent.components &&
                  selectedContent.components.length
                "
              >
                <sortable-list v-model="activeComponents" @drag-end="onDragEnd">
                  <transition-group
                    name="components"
                    tag="div"
                    class="list-reset"
                    slot-scope="{ items }"
                  >
                    <sortable-item
                      v-for="(content, index) in items"
                      :key="`content-component-${index}`"
                    >
                      <div class="sortable-component">
                        <sortable-handle>
                          <span style="cursor: move">
                            <svg
                              class="
                                ml-2
                                h-4
                                w-4
                                cursor-move
                                text-grey
                                hover:text-grey-darkest
                              "
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M14 4h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1zM8 4h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1zm6 6h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1zm-6 0h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1zm6 6h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1zm-6 0h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1z"
                              />
                            </svg>
                          </span>
                        </sortable-handle>
                        <content-component
                          :content="content"
                          :viewType="selectedContent.type"
                          :viewId="selectedContent.id"
                          :actions="actions"
                        />
                      </div>
                    </sortable-item>
                  </transition-group>
                </sortable-list>
              </template>
              <template v-else>
                <content-component
                  v-if="
                    selectedContent.type === 'lander' ||
                    selectedContent.type === 'email' ||
                    selectedContent.type === 'sms'
                  "
                  :content="null"
                  :viewId="selectedContent.id"
                  :viewType="selectedContent.type"
                />
              </template>
            </template>
            <div v-else class="card">
              <div class="empty-view-wrapper">
                <p><strong>No Touchpoint Selected</strong></p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import ToggleSwitch from "@/components/ToggleSwitch";
import SlideToggle from "@/components/SlideToggle";
import ContentComponent from "@/components/ContentComponent";
import ActionButton from "@/components/ActionButton";
import CreateViewModal from "@/components/CreateViewModal";
import CreateAdModal from "@/components/CreateAdModal";
import Pill from "@/components/Pill";
import PreviewIframe from "@/components/PreviewIframe";
import { demographics } from "@/mixins/demographics";
import { content } from "@/mixins/content";
import { events } from "@/mixins/events";
import Ad from "@/models/Ad";
import View from "@/models/View";
import axios from "axios";
import MenuButton from "@/components/MenuButton";
import SortableList from "@/components/sortable/SortableList";
import SortableItem from "@/components/sortable/SortableItem";
import SortableHandle from "@/components/sortable/SortableHandle";

export default {
  name: "Campaign",
  components: {
    Loading,
    ToggleSwitch,
    ContentComponent,
    SortableList,
    SortableItem,
    SortableHandle,
    SlideToggle,
    Pill,
    ActionButton,
    CreateViewModal,
    CreateAdModal,
    PreviewIframe,
    MenuButton,
  },
  mixins: [content, events, demographics],
  data() {
    return {
      activeComponents: [],
      createViewModalActive: false,
      newView: null,
      newAd: null,
      createAdModalActive: false,
      previewUrl: null,
      viewTypes: ["lander", "email", "sms"],
    };
  },
  watch: {
    selectedContent() {
      this.activeComponents = this.selectedContent.components;
    },
  },
  computed: {
    selectedEvents: {
      get() {
        const events = this.selectedContent.events.map(function (event) {
          return event.id;
        });

        return events;
      },
      set(events) {
        this.selectedContent.events = events.map(function (event) {
          return {
            id: event,
          };
        });
      },
    },
    loading() {
      return this.$store.getters["engage/loading"];
    },
    actions() {
      if (!this.selectedContent) {
        return [];
      }
      return this[`${this.selectedContent.type}Actions`];
    },
    baseURL() {
      return process.env.VUE_APP_REMOTE_FRONTEND_BASE;
    },
    adExportUrl() {
      return (
        process.env.VUE_APP_REMOTE_BASE +
        "/exports/v1/campaign/ads/" +
        this.campaign.id
      );
    },
    filteredTags() {
      return null;
    },
  },
  methods: {
    onDragEnd(components) {
      for (let i = 0; i < components.length; i++) {
        components[i].priority = i;
      }
      this.selectedContent.components = components;
      this.$store.dispatch("engage/updateComponents", this.selectedContent);
    },
    onCreateNewAd() {
      this.newAd = new Ad();
      this.newAd.campaign_id = this.$route.params.id;
      this.createAdModalActive = true;
    },
    onCreateNewView(type) {
      this.newView = new View();
      this.newView.type = type;
      this.newView.campaign_id = this.$route.params.id;
      this.createViewModalActive = true;
    },
    onViewCreated(view) {
      this.createViewModalActive = false;
      this.$store.dispatch("engage/setSelectedContent", view);
    },
    onAdCreated(ad) {
      this.createAdModalActive = false;
      this.$store.dispatch("engage/setSelectedContent", ad);
    },
    onDefaultSelected() {
      // Look for current default
      let currentDefault = this.landers.find((lander) => {
        return lander.is_default == 1 && lander.id != this.selectedContent.id;
      });

      if (typeof currentDefault != "undefined") {
        currentDefault.is_default = 0;
        this.$store.dispatch("engage/updateView", currentDefault);
      }

      this.onContentChanged();
    },
    onViewStatusChange(val) {
      this.selectedContent.status = val ? "active" : "inactive";
      this.onContentChanged();
    },
    onViewChatChange(val) {
      this.selectedContent.meta_data.chat = val ? "active" : "inactive";
      this.onContentChanged();
    },
    onContentChanged() {
      // Save the content
      let route =
        this.viewTypes.indexOf(this.selectedContent.type) === -1
          ? "engage/updateAd"
          : "engage/updateView";
      this.$store.dispatch(route, this.selectedContent).then((res) => {
        if (res.data) {
          /**
           * !!TEMP HACK!!
           * When we refactor content management, we will make this better :)
           */
          if (res?.data?.slug && this?.selectedContent?.slug) {
            this.selectedContent.slug = res.data.slug;
          }
        }
      });
    },
    onCloneContent() {
      this.$store
        .dispatch("engage/cloneView", this.selectedContent)
        .then((res) => {
          if (res.status < 300) {
            this.loadCampaign();
          }
        });
    },
    onDeleteContent() {
      this.$store
        .dispatch("engage/deleteView", this.selectedContent)
        .then((res) => {
          if (res.status < 300) {
            this.$store.dispatch("engage/setSelectedContent", null);
          }
        });
    },
    onStatusChange(val) {
      this.campaign.status = val ? "active" : "inactive";
      this.$store.dispatch("engage/changeCampaignStatus", this.campaign);
    },
    onTagClicked(tag) {
      const i = this.tagIndex(tag);
      if (i === -1) {
        this.selectedContent.tags.push(tag);
      } else {
        this.selectedContent.tags.splice(i, 1);
      }

      this.$store.dispatch("engage/addTags", this.selectedContent);
    },
    tagIndex(tag) {
      if (this.selectedContent.tags && this.selectedContent.tags.length) {
        return this.selectedContent.tags.findIndex((item) => {
          return item.value === tag.value;
        });
      }

      return -1;
    },
    getBlobURL(code, type) {
      const blob = new Blob([code], { type });
      return URL.createObjectURL(blob);
    },
    onPreviewLander(url) {
      window.open(url, "_blank");
    },
    onPreviewEmail() {
      this.previewEmail("default");
    },
    previewEmail(theme) {
      if (!theme) {
        theme = "default";
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_EMAIL_API}/email/${this.selectedContent.id}/${theme}`
          )
          .then((res) => {
            this.previewUrl = this.getBlobURL(res.data.data.email, "text/html");

            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    testEmail(theme, email_address) {
      const post_data = {
        email_id: this.selectedContent.id,
        theme: theme,
        email_address: email_address,
        tokens: {
          "!!first_name!!": "Testing",
        },
      };

      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_EMAIL_API}/email/send`, post_data)
          .then((res) => {
            this.flashMessage.success({
              title: "Test email sent",
            });
            resolve(res);
          })
          .catch((err) => {
            this.flashMessage.error({
              title: "Test email could not be sent",
            });
            reject(err);
          });
      });
    },
    onPreviewClose() {
      this.previewUrl = null;
    },
    onSaveCampaign() {
      this.$store.dispatch("engage/saveCampaign", this.campaign);
    },
    onDownloadAdURLs(url) {
      window.open(url, "_blank");
    },
    onSelectView(view) {
      this.$store.dispatch("engage/setSelectedContent", view);
    },
    loadCampaign() {
      // Get the campaign id
      const id = this.$route.params.id;
      this.$store.dispatch("engage/setSelectedContent", null);
      this.$store.dispatch("engage/getCampaign", { campaign_id: id });
    },
  },
  mounted() {
    this.loadCampaign();
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/button";
@import "@/scss/forms";
@import "@/scss/hero";

.campaign {
  .menu-settings {
    h3 {
      margin-bottom: 16px;
    }

    textarea {
      max-height: 80px;
    }

    input:last-of-type {
      margin-bottom: 0;
    }
  }

  .label-button {
    display: flex;
    align-items: center;

    &__button {
      margin-left: auto;
    }
  }

  .hero {
    &__controls {
      margin-left: auto;
      display: flex;
      align-items: center;

      .menu-button {
        margin-left: 16px;
      }
    }

    .container {
      height: calc(100% - 8rem);
      display: flex;
      align-items: center;
      color: #fff;

      h2 {
        margin-bottom: 0 !important;
        font-size: 1.5rem !important;
        font-weight: 600;
      }
    }

    & + .container {
      margin-top: -8rem;
    }
  }

  .container {
    .campaign-components-wrapper {
      display: flex;

      .campaign-wrapper {
        width: 33.3333%;
        padding-right: 1rem;

        .card:first-of-type {
          padding: 0 !important;

          .slide-toggle {
            header {
              padding: 1.5rem;
              border-bottom: solid 2px $fade-gray;
              font-weight: 800;
              display: flex;
              align-items: center;

              .pill {
                margin-left: auto;
              }

              button {
                margin-left: 8px;
              }
            }

            &:last-of-type {
              header {
                border-bottom: none;
              }
            }

            &-body {
              padding: 0;
              border-bottom: solid 2px $fade-gray;
              background: #f7f7f7;

              ul {
                li {
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  padding: 1rem;
                  border-bottom: solid 1px $fade-gray;
                  font-size: 0.875rem;
                  transition: color 0.425s, background 0.425s;

                  svg {
                    margin-left: auto;
                    fill: $disabled;
                    width: 0.875rem;
                    height: 0.875rem;
                    transition: fill 0.425s;
                  }

                  &:hover {
                    color: $dark-blue;

                    svg {
                      fill: $green;
                    }
                  }

                  &:last-of-type {
                    border-bottom: none;
                  }

                  &.selected {
                    background: $bright-green;
                    color: $dark-blue;

                    svg {
                      fill: #fff;
                    }
                  }
                }
              }

              p {
                padding: 1rem;
                font-size: 14px;
                color: $red;
              }
            }
          }
        }

        .audiences {
          margin-top: 1.5rem;

          .label {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 0.5rem;
          }

          .segment-chart {
            margin-bottom: 1.5rem;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }

        .campaign-exports {
          margin-top: 1.5rem;

          label {
            margin-right: 12px;
          }
        }
      }

      .components-wrapper {
        width: 66.6666%;
        padding-left: 1rem;

        & .card.active:first-of-type {
          margin-bottom: 2rem;
          padding-right: 1.5rem !important;
          position: relative;
          padding-bottom: 0 !important;
        }

        header {
          &.components-wrapper-header {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;

            .components-wrapper-header__input {
              background: transparent;
              border: none;
              outline: none;
              font-size: 1.5rem;
              font-family: "Nunito Sans", sans-serif;
              padding: 0;
              width: calc(100% - 128px);
            }

            .controls {
              margin-left: auto;
              display: flex;
              align-items: center;

              .control {
                margin-left: 1rem;
                cursor: pointer;

                &.button {
                  background: transparent;
                  color: $gray;
                  border: solid 1px $disabled;
                }
              }
            }
          }
        }

        .default-toggle-wrapper {
          border-bottom: solid 1px $fade-gray;
          margin-bottom: 16px;
          font-size: 14px;
          padding: 0 0 8px 0;
          display: flex;
          align-items: center;

          .toggle-switch {
            .slider {
              background-color: $fade-gray;
            }
            input:checked + .slider {
              background-color: $dark-blue;
            }
          }

          .toggle-switch,
          input[type="checkbox"] {
            margin-right: 8px;
          }
        }

        .tags-wrapper {
          margin-bottom: 0.75rem;

          .pill {
            margin-right: 0.25rem;
            margin-bottom: 0.25rem;
          }
        }

        .content-settings {
          background-color: #f7f7f7;
          border-top: solid 2px $fade-gray;
          display: flex;
          margin: 0 -1.5rem 0 -2rem;
          border-radius: 0 0 8px 8px;

          .cell {
            flex-grow: 1;
            padding: 1rem;
            border-right: solid 2px $fade-gray;

            label {
              color: $medium-gray;
            }

            &:last-of-type {
              border-right: none;
            }

            select {
              margin-bottom: 0 !important;
              border-bottom: none;
            }

            input[type="text"] {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
  }
}

.sortable-component {
  position: relative;

  .sortable-handle {
    left: 8px;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 36px;
    color: $medium-gray;
  }
}
</style>