function Ad() {
	return {
		id: null,
		campaign_id: null,
		name: null,
		type: null,
		source: null,
		source_id: null,
		view_id: null,
		created_at: null,
		updated_at: null
	}
}

export default Ad;