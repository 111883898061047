<template>
  <div class="menu-button" v-click-outside="onClose">
    <button
      class="menu-button__button"
      :class="buttonClasses"
      @click="active = !active"
    >
      <span v-if="label">{{ label }}</span>
      <BaseSVG v-else :src="require('@/assets/menu-icon.svg')" />
    </button>
    <transition name="fade-up">
      <div
        class="menu-button__menu"
        :class="{
          'menu-button__menu--right': position === 'right',
          'menu-button__menu--small': width === 'small',
        }"
        v-if="active"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import BaseSVG from "@/components/base/BaseSVG";

export default {
  name: "MenuButton",
  props: {
    label: {
      type: String,
      required: false,
    },
    position: {
      type: String,
      required: false,
      default: "left",
    },
    buttonClasses: {
      type: String,
      required: false,
      default: "",
    },
    width: {
      type: String,
      required: false,
    },
  },
  components: {
    BaseSVG,
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    extendedButtonClasses() {
      let classes = new String();

      if (this.buttonClasses) {
        classes += ` ${this.buttonClasses}`;
      }

      if (this.label) {
        classes += " button";
      }

      return classes;
    },
  },
  methods: {
    onClose() {
      this.active = false;
    },
  },
};
</script>

<style lang="scss">
.fade-up-enter-active,
.fade-up-leave-active {
  transition: opacity 0.425s,
    transform 0.425s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.fade-up-enter,
.fade-up-leave-to {
  opacity: 0;
  transform: translate3d(0, 16px, 0);
}
.fade-up-enter-to {
  transform: translate3d(0, 0, 0);
}

.menu-button {
  position: relative;
  display: inline-block;

  button:not(.button) {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  &__menu {
    position: absolute;
    background-color: #fff;
    padding: 0;
    border-top: none;
    width: 100%;
    max-height: 512px;
    width: 360px;
    overflow-y: auto;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 24px;
    color: #343434;
    margin-top: 8px;
    text-align: left;
    z-index: 3;

    &--right {
      right: 0;
    }

    &--small {
      width: 256px;
    }
  }
}
</style>