<template>
  <div class="content-component-picker">
    <transition name="fade">
      <div
        class="content-component-picker-overlay"
        v-if="active"
        @click="$emit('dismiss-content-type-picker')"
      ></div>
    </transition>

    <transition name="fade-up">
      <section class="content-component-picker-wrapper" v-if="active">
        <div class="content-component-picker-content">
          <h2>Select A Component Type</h2>
          <div class="content-component-picker-grid">
            <div
              class="content-component-picker-type"
              v-for="(contentType, name) in componentTypes"
              :key="`content-${name}`"
              @click="$emit('content-type-selected', name)"
            >
              <img :src="contentType.icon" />
              <p class="content-component-picker-type-label">
                {{ contentType.label }}
              </p>
            </div>
          </div>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import { content } from "@/mixins/content";

export default {
  name: "ContentComponentPicker",
  props: ["active", "viewType"],
  mixins: [content],
  computed: {
    componentTypes: function () {
      const types = {};

      for (var k in this.contentTypes) {
        if (this.contentTypes[k].views.indexOf(this.viewType) != -1) {
          types[k] = this.contentTypes[k];
        }
      }

      return types;
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.content-component-picker {
  &-overlay,
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  &-overlay {
    background: $dark-blue-overlay;
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  &-content {
    pointer-events: auto;
    width: 700px;
    max-height: 100%;
    background: #fff;
    box-shadow: 0 32px 64px $dark-blue-overlay;
    padding: 2rem;
    overflow-y: auto;
    border-radius: 8px;
  }

  &-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-type {
    padding: 0.75rem;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.425s;

    &:hover {
      transform: scale(1.05);
    }

    &-label {
      margin-top: 0.5rem;
      font-weight: 800;
    }
  }
}
</style>